/* Spinner Styles */
.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.spinner-border {
  width: 3rem;
  height: 3rem;
  border-width: 0.3rem;
}

/* Leaderboard Page */
.leaderboard-page {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  /*background-color: rgb(77, 31, 1); remove backgrounds*/
  padding: 1rem;
}

/* Category List */
.category-list {
  width: 20%;
  padding: 1rem;
  border-right: 1px solid #ddd;
}

.category-item {
  padding: 0.75rem;
  cursor: pointer;
  margin-bottom: 0.5rem;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.category-item:hover {
  background-color: #e9ecef;
}

/* Leaderboard */
.leaderboard {
  width: 75%;
  margin: 1rem;
  border-radius: 10px;
  /*box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);*/
  padding: 1.5rem;
}

.leader-list {
  margin-top: 1rem;
}

.leader-item {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
  border-bottom: 1px solid #e0e0e0;
}

.expand-btn {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.expand-btn:hover {
  background-color: #0056b3;
}

/* Heading Styles */
.category-heading {
  font-size: 1.5rem;
  margin-bottom: 10px;
  text-align: center;
}

/* Responsive Design */
@media (max-width: 768px) {
  .leaderboard-page {
    flex-direction: column;
    align-items: center;
  }

  .category-list {
    width: 100%;
    padding: 0.5rem;
    border-right: none;
    border-bottom: 1px solid #ddd;
  }

  .leaderboard {
    width: 100%;
    margin: 1rem 0;
  }
}

@media (max-width: 480px) {
  .category-item {
    padding: 0.5rem;
    font-size: 0.9rem;
  }

  .leader-item {
    font-size: 0.9rem;
    flex-direction: column;
    align-items: flex-start;
  }

  .leader-item span {
    margin-bottom: 0.3rem;
  }
}
