/* 
main:has(.explorer){
    background-color: red;
    min-height: 85dvh;
} */

.explorer{
    min-height: 85dvh;
    color: black;
}

.explorer > div{
    width: 25%;
    min-height: 250px;
}

.explorer > div img{
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 5px 5px 0 0 ;
}