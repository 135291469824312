#gameImageUploadHolder{
    width: 300px;
    height: 300px;
    position: relative;
    overflow: auto;
}

#gameImageUploadHolder > div{
    z-index: 1;
    position: sticky;
    top: 0;
    left: 10px;
    width: 200px;
    color: black;
}

#gameImageUploadHolder img{
    position: absolute;
    z-index: 0;
    /* width: 100%; */
}