@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Lobster&family=Merriweather:ital,wght@0,300;0,900;1,300;1,900&family=Nunito+Sans:opsz,wght@6..12,300;6..12,1000&family=Rubik+Vinyl&display=swap');

:root{
  --primary-color: rgb(77, 31, 1);
  --secondary-color: rgb(66, 20, 75);
  --call-2-action-color: orange;
}

*{
  /* padding: 0; */
  /* margin: 0; */
  box-sizing: 0;
}

#root{
 display: flex;
 flex-direction: column;
 font-family: 'Nunito Sans', sans-serif;
 font-size: 16px;
 background: linear-gradient(var(--primary-color), var(--secondary-color)) no-repeat;
 background-attachment: fixed;
 background-size: auto 100%;
 min-height: 100vh;
 color: white;
 padding: 0;
 margin: 0;
}

h1,h2,h3,h4{
  margin: 0;
  padding: 0;
}

a{
  text-decoration: none;
}

a:hover{
  text-decoration: none;
}

header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  backdrop-filter: blur(0.625rem);
  background: rgba(0, 0, 0, .3);
  padding-top: 0.625em;
  z-index: 10;
}

header h1{
  padding: 0 0.6325em;
}

header h1 i{
  margin-right: 0.125em;
  font-family: 'Merriweather', serif;
  font-weight: 900;
  font-style: italic;
}

header nav{
  display: flex;
}

header nav ul{
  display: flex;
  list-style: none;
  align-items: center;
  gap: 0.6325em;
  margin: 0;
  padding: 0;
}

header a{
  display: block;
  text-decoration: none;
  font-weight: 600;
  color: black;
  color: white;
  white-space: nowrap;
  border-bottom: 2px solid rgba(0, 0, 0, 0);
}

header h1 a{
  padding: 0 0.6325em;
  font-weight: 900;
}

header nav a{
  padding: 1.25em 1.875em;
}

header nav a:hover, header nav a:active, header nav a.active{
  color: var(--call-2-action-color);
  border-bottom: 2px solid white;
}

main{
  color: white;
  min-height: calc(100dvh - 180px);
  /* overflow: hidden; */
}

.btn{
  border-radius: 0.325em;
  border: none;
  background: none;
  padding: 0.625em;
  font-size: 0.75em;
  cursor: pointer;
}

.btn:hover, .btn:active{
  background-color: rgba(255, 255, 255, .4);
}

.play-btn{
  background: linear-gradient(0deg, rgba(219,154,0,1) 0%, rgba(254,230,90,1) 75%, rgba(255,209,0,1) 100%);
  color: rgb(97, 63, 1);
  /* margin: 1.25em 1.875em; */
  /* padding: 0.6325em 1.875em; */
}

.play-btn:hover{
  background: linear-gradient(0deg, rgb(187, 131, 0) 0%, rgb(226, 204, 79) 75%, rgb(226, 185, 0) 100%);
  text-shadow: 1px 1px 1px rgb(211, 203, 167);
}

section.wrapper{
  /* min-height: 450px; */
}

section.wrapper > :first-child, section.wrapper > :last-child{
  padding-right: 0.6325em;
}

.glass-card{
  background: rgba(255, 255, 255, .5);
}