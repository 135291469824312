
dialog#gameDialog{
    width: 90dvw;
    height: 90dvh;
    border: none;
    backdrop-filter: blur(0.625rem);
    background: rgb(255, 255, 255);
    border-radius: 10px;
    padding: 0;
}

dialog#gameDialog::backdrop{
    /* background: red; */
    /* width: 100%; */
}

.games-grid button img.popedOver{
    display: none;
    z-index: 10;
}

.games-grid button:hover img.popedOver{
    display: block;
}

.gameBtn{
    position: relative;
}

.slotPopOver{
    top: 0;
    left: 0;
    position: absolute;
}

.slotPopOver:popover-open{
    position: absolute;
    inset: unset;
}


@media(max-width:480px){
    dialog#gameDialog{
        width: 100vw !important;
        height: 100dvh;
        border-radius: 0;
        /* background-color: red; */
    }
}