.puzzle-container{
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    /* border: 1px solid red; */
}

.game-stats{
    padding: 10px 0;
    display: flex;
    min-height: 30px;
    font-size: 1.125rem;
    line-height: 1;
    text-align: center;
    justify-content: space-between;
    width: 100%;
}

.game-stats > div{
    margin-right: 10px;
}

.game-board{
    position: relative;
    width: 100cqw;
    max-width: 640px;
    box-sizing: 0;
    z-index: 0;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 5px black;
}

.tile{
    position: absolute;
    box-sizing: 0;
    user-select: none;
    text-shadow: 0, 0, 1px yellow !important;
    /* border: 1px solid red; */
}

.tile div{
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
}

.movable-tiles{
    font-weight: bolder;
    /* border: 1px solid red;
    font-size: 0.6rem; */
    color: red;
    z-index: 1;
}

.movable-tiles > div{
    height: 100%;
}

.movable-tiles:hover{
    box-shadow: inset 0 0 10px black;
}

.empty-tile{
    background-image: none;
    background-color: rgba(0, 0, 0, 0);
    z-index: 0;
    box-shadow: inset 0 0 10px black;
}

.empty-tile{
    display: flex;
    flex-wrap: wrap;
    font-weight: bolder;
    color: white;
    text-shadow: 0 0 3px black;
}

.empty-tile div{
    width: calc(100% / 3);
    height: calc(100% / 3);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.empty-center{
    text-align: center;
    line-height: 1;
}