.navbar {
    backdrop-filter: blur(0.625rem);
    background: rgba(0, 0, 0, .3);
    color:white;
}

.navbar a{
    color: white;
}

.navbar a.active{
    color: orange !important;
}

.menus{
    height: 100%;
}

footer{
    background-color: var(--secondary-color);
}

footer .link-group a{
    text-decoration: none;
}


.social-bar {
    padding: 10px;
  }
  
  .nav-links {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  .nav-links li {
    display: inline;
    margin-right: 10px; /* Add space between links */
  }
  
  .nav-links li:last-child {
    margin-right: 0; /* Remove margin for the last link */
  }
  
  .nav-links li a {
    color: white;
    text-decoration: none;
  }

/* header .play-btn {
    margin: 1.25em 1.875em;
    padding: 0.6325em 1.875em;
}

nav.main-nav button{
    display: none;
}

nav{
    
}

@media(max-width:768px) {

    nav.main-nav ul{
        display: none;
    }

    nav.main-nav{
    }

    nav.main-nav button{
        display: block;
        margin-left: auto;
        color: white;
    }
} */