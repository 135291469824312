.home-hiro{
    display: flex;
    /* align-items: stretch; */
    justify-content: center;
    background: linear-gradient(rgba(0, 0, 0, 0.432), rgba(0, 0, 0, 0.856)), url(/public/images/the-space.jpg) bottom;
    background-size: cover;
    background-attachment: fixed;
    /* color: #000000; */
    text-shadow: 0 0 15px #000000;
    /* height: 100%; */
    width: 100%;
    /* border: 1px solid red; */
    white-space: nowrap;
    background-color: red;
}

.home-hiro > div{
    /* height: calc(50cqh - (1.875em * 2)); */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
    /* border: 1px solid red; */
    text-align:justify;  
    text-justify:distribute-all-lines;
    height: 70dvh;
    color: white;
}

.home-hiro > div:first-child{
    /* flex-grow: 1; */
    padding: 1.875em;
    width: calc(60cqw - (1.875em * 2));
}

.home-hiro img{
    /* width: 100%; */
    height: 120%;
    position: sticky;
    top: 0;
    /* object-fit: contain; */
    /* border: 1px solid white; */
}

.home-hiro hgroup{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* height: 100cqh; */
}

.home-hiro h2{
    font-weight: bolder;
    font-size: 3.25em;
    font-style: italic;
    font-family: 'Lobster', cursive;
    /* color: rgb(255, 92, 92); */
    margin-bottom: 0.325em;
}

.home-hiro h3{
    font-size: 1.5em;
    line-height: 1;
    margin-bottom: 0.625em;
}

.home-hiro button{
    padding: 0.6325em 1.875em;
    border: 2px solid rgb(165, 107, 0);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.527);
    font-size: 0.875em;
    font-weight: bolder;
    color: white;
}

.home-hiro button.play-btn{
    color: black;
}

.instruction{
    background-color: var(--secondary-color);
    /* height: 100dvh; */
}

.instruction div:has(img.play){
    /* background: black; */
}

img.play{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/* @media(max-width:768px) {

    .home-hiro{
        width: 100%;
        word-break: break-all;
    }

    .home-hiro div{
        text-align: left;
    }

    .home-hiro > div:first-child{
        padding: 1.5em;
    }

    .home-hiro h2{
        font-weight: bolder;
        font-size: 2em;
        font-style: italic;
        font-family: 'Lobster', cursive;
        margin-bottom: 0.325em;
    }

    .home-hiro h3{
        font-size: 1em;
        line-height: 1;
        margin-bottom: 0.625em;
    }

} */

@media(max-width:768px){

    .home-hiro{
        flex-direction: column;
        /* border: 1px solid red; */
        white-space: break-spaces;
    }

    .home-hiro > div{
        display: flex;
        justify-content: end;
        background: url(/public/images/m.png) center no-repeat;
        width: 100dvw !important;
        padding: 0;
        height: 80dvh;
    }

    .home-hiro > div > div{
        width: 100%;
        padding: 20px;
    }

    .home-hiro h2{
        font-size: 2.5rem;
    }

    .home-hiro h3{
        font-size: 1.125rem;
    }


    .home-hiro > div:first-child{
        padding:0.625em 0;
    }

    .home-hiro > div:last-child{
       display: none;
    }
}